import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
    declarations: [],
    exports: []
})
export class IconRegisterModule {
    // Find svg icons here: https://materialdesignicons.com/
    private _icons: { name: string; path: string }[] = [
        { name: 'auto_fix', path: 'assets/icons/auto_fix.svg' },
        { name: 'account_key', path: 'assets/icons/account_key.svg' },
        { name: 'file-ai', path: 'assets/images/mapui/filetypes/ai.svg' },
        { name: 'file-avi', path: 'assets/images/mapui/filetypes/avi.svg' },
        { name: 'file-css', path: 'assets/images/mapui/filetypes/css.svg' },
        { name: 'file-dbf', path: 'assets/images/mapui/filetypes/dbf.svg' },
        { name: 'file-doc', path: 'assets/images/mapui/filetypes/doc.svg' },
        { name: 'file-dwg', path: 'assets/images/mapui/filetypes/dwg.svg' },
        { name: 'file-file', path: 'assets/images/mapui/filetypes/file.svg' },
        { name: 'file-iso', path: 'assets/images/mapui/filetypes/iso.svg' },
        { name: 'file-javascript', path: 'assets/images/mapui/filetypes/javascript.svg' },
        { name: 'file-jpg', path: 'assets/images/mapui/filetypes/jpg.svg' },
        { name: 'file-mp3', path: 'assets/images/mapui/filetypes/mp3.svg' },
        { name: 'file-pdf', path: 'assets/images/mapui/filetypes/pdf.svg' },
        { name: 'file-png', path: 'assets/images/mapui/filetypes/png.svg' },
        { name: 'file-ppt', path: 'assets/images/mapui/filetypes/ppt.svg' },
        { name: 'file-psd', path: 'assets/images/mapui/filetypes/psd.svg' },
        { name: 'file-rtf', path: 'assets/images/mapui/filetypes/rtf.svg' },
        { name: 'file-svg', path: 'assets/images/mapui/filetypes/svg.svg' },
        { name: 'file-txt', path: 'assets/images/mapui/filetypes/txt.svg' },
        { name: 'file-xls', path: 'assets/images/mapui/filetypes/xls.svg' },
        { name: 'file-html', path: 'assets/images/mapui/filetypes/html.svg' },

        { name: 'alert-box', path: 'assets/flaticon/alert-box.svg' },
        { name: 'menu-open', path: 'assets/flaticon/menu-open.svg' },
        { name: 'beta', path: 'assets/flaticon/beta.svg' },
        { name: 'epi', path: 'assets/flaticon/safety-goggles.svg' },
        { name: 'map-marker-multiple', path: 'assets/flaticon/map-marker-multiple.svg' },
        { name: 'medical-device', path: 'assets/flaticon/md_logo.svg' },
        { name: 'medication', path: 'assets/flaticon/medication_black_24dp.svg' },
        { name: 'package-variant-closed', path: 'assets/flaticon/package-variant-closed.svg' },
        { name: 'package-variant', path: 'assets/flaticon/package-variant.svg' },
        { name: 'pill', path: 'assets/flaticon/pill.svg' },
        { name: 'preparation', path: 'assets/flaticon/prep_logo.svg' },
        { name: 'needle', path: 'assets/flaticon/needle.svg' },
        { name: 'factory', path: 'assets/flaticon/factory.svg' },
        { name: 'cart-remove', path: 'assets/flaticon/cart-remove.svg' },
        { name: 'sitemap', path: 'assets/flaticon/sitemap.svg' },
        { name: 'email-lock', path: 'assets/flaticon/email-lock.svg' },
        { name: 'dot', path: 'assets/flaticon/dot.svg' },
        { name: 'filter-variant-plus', path: 'assets/flaticon/filter-variant-plus.svg' }
    ];

    constructor(private _iconRegistry: MatIconRegistry, private _sanitizer: DomSanitizer) {
        for (const elem of this._icons) {
            _iconRegistry.addSvgIcon(elem.name, _sanitizer.bypassSecurityTrustResourceUrl(elem.path));
        }
    }
}
