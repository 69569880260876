export const locale = {
    lang: 'en',
    data: {
        TOOLBAR: {
            TOGGLE_NAVIGATION: 'Toggle navigation',
            TOGGLE_NAVIGATION_FOLD: 'Toggle navigation fold',
            TOGGLE_NAVIGATION_MODE: 'Toggle navigation mode',
            SEARCH: 'Search',
            USER_SETTINGS: 'User settings',
            TOGGLE_QUICK_PANEL: 'Toggle quick panel',
            ENGLISH: 'English',
            SPANISH: 'Spanish',
            TURKISH: 'Turkish',
            FRENCH: 'French',
            PROFILE: {
                MY_PROFILE: 'My profile',
                LOGOUT: 'Logout'
            },
            NAV: {
                HOME: 'Home',
                DASHBOARD: 'Dashboard',
                MAP: 'Map'
            },
            INFO_BAR: 'The platform has been updated, click here to find out more'
        },
        NOTIFICATIONS: {
            TITLE: 'Notifications',
            MARK_ALL_AS_READ: 'Mark all as read',
            SETTINGS: 'Settings',
            NO_NOTIF: 'No notification',
            ALL_LOADED: 'End of notifications',
            RELATIVE_TIME: '{{diff}} ago',
            LOAD_MORE: 'See more',
            LABEL: {
                LOAN: {
                    CREATE: '<strong>{{hospital}}</strong> lend you {{nbr}} <strong>{{drug}}</strong>',
                    ANSWER: '<strong>{{hospital}}</strong> take {{nbr}} <strong>{{drug}}</strong>',
                    CONFIRM: '<strong>{{hospital}}</strong> confirmed the {{nbr}} <strong>{{drug}}</strong> loan',
                    RETURN: '<strong>{{hospital}}</strong> returned the {{nbr}} <strong>{{drug}}</strong> loan',
                    BILL: '<strong>{{hospital}}</strong> billed the {{nbr}} <strong>{{drug}}</strong> loan',
                    SETTLE: "<strong>{{hospital}}</strong> settled the {{nbr}} <strong>{{drug}}</strong> loan's bill"
                },
                BORROWING: {
                    CREATE: '<strong>{{hospital}}</strong> borrows you {{nbr}} <strong>{{drug}}</strong>',
                    ANSWER: '<strong>{{hospital}}</strong> lend you {{nbr}} <strong>{{drug}}</strong>',
                    CONFIRM: '<strong>{{hospital}}</strong> confirmed the {{nbr}} <strong>{{drug}}</strong> borrow',
                    RETURN: '<strong>{{hospital}}</strong> returned the {{nbr}} <strong>{{drug}}</strong> borrow',
                    BILL: '<strong>{{hospital}}</strong> billed the {{nbr}} <strong>{{drug}}</strong> loan',
                    SETTLE: "<strong>{{hospital}}</strong> settled the {{nbr}} <strong>{{drug}}</strong> borrow's bill"
                },
                AVAILABLE: {
                    CREATE: '<strong>{{hospital}}</strong> put {{nbr}} <strong>{{drug}}</strong> available',
                    REQUEST: '<strong>{{hospital}}</strong> wish to take {{nbr}} <strong>{{drug}}</strong>',
                    EXPIRE: 'The availability of {{nbr}} <strong>{{drug}}</strong> expired'
                },
                PREPARATION: {
                    CREATE: '<strong>{{hospital}}</strong> prepare for you {{nbr}} <strong>{{drug}}</strong>',
                    REQUEST:
                        '<strong>{{hospital}}</strong> ask for the preparation of {{nbr}} <strong>{{drug}}</strong>',
                    VALIDATE: '<strong>{{hospital}}</strong> validated the preparation of <strong>{{drug}}</strong>',
                    CONFIRM: '<strong>{{hospital}}</strong> freed the preparation of <strong>{{drug}}</strong>',
                    'VALIDATE-RECEPTION':
                        '<strong>{{hospital}}</strong> received the preparation of <strong>{{drug}}</strong>',
                    BILL: '<strong>{{hospital}}</strong> billed the preparation of {{nbr}} <strong>{{drug}}</strong>',
                    SETTLE: '<strong>{{hospital}}</strong> settled the preparation of {{nbr}} <strong>{{drug}}</strong> bill'
                },
                REQUEST: {
                    CREATE: '<strong>{{hospital}}</strong> needs {{nbr}} <strong>{{drug}}</strong>',
                    EXPIRE: 'Your request of {{nbr}} <strong>{{drug}}</strong> expired'
                },
                HOSPITAL: {
                    'RANK-MEMBER': 'Your establishment is now a premium member'
                },
                SHAREDFILE: {
                    CREATE: 'The file <strong>{{filename}}</strong> has been shared with you in folder <strong>{{parent}}</strong>'
                }
            }
        },
        VAULT_MODAL: {
            TITLE: 'Inactivity detected',
            CONTENT: 'Your session will be locked.'
        }
    }
};
