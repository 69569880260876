export const locale = {
    lang: 'fr',
    data: {
        TOOLBAR: {
            TOGGLE_NAVIGATION: 'Navigation',
            TOGGLE_NAVIGATION_FOLD: 'Déplier / Replier la navigation',
            TOGGLE_NAVIGATION_MODE: 'Mode de navigation',
            SEARCH: 'Recherche',
            USER_SETTINGS: 'Paramètres Utilisateurs',
            TOGGLE_QUICK_PANEL: 'Accès rapide',
            ENGLISH: 'Anglais',
            SPANISH: 'Espagnol',
            TURKISH: 'Turc',
            FRENCH: 'Français',
            PROFILE: {
                MY_PROFILE: 'Mon profil',
                LOGOUT: 'Déconnexion'
            },
            NAV: {
                HOME: 'Accueil',
                DASHBOARD: 'Tableau de bord',
                MAP: 'Carte'
            },
            INFO_BAR: 'La plateforme a été mise à jour, cliquez ici pour en savoir plus'
        },
        NOTIFICATIONS: {
            TITLE: 'Notifications',
            MARK_ALL_AS_READ: 'Tout marquer comme lu',
            SETTINGS: 'Paramètres',
            NO_NOTIF: 'Aucune notification',
            ALL_LOADED: 'Fin des notifications',
            RELATIVE_TIME: 'Il y a {{diff}}',
            LOAD_MORE: 'Voir plus',
            LABEL: {
                LOAN: {
                    CREATE: '<strong>{{hospital}}</strong> vous prête {{nbr}} <strong>{{drug}}</strong>',
                    ANSWER: '<strong>{{hospital}}</strong> reprends {{nbr}} <strong>{{drug}}</strong>',
                    CONFIRM: '<strong>{{hospital}}</strong> a confirmé le prêt de {{nbr}} <strong>{{drug}}</strong>',
                    RETURN: '<strong>{{hospital}}</strong> a retourné le prêt de {{nbr}} <strong>{{drug}}</strong>',
                    BILL: '<strong>{{hospital}}</strong> a facturé le prêt de {{nbr}} <strong>{{drug}}</strong>',
                    SETTLE: '<strong>{{hospital}}</strong> a liquidé la facture du prêt de {{nbr}} <strong>{{drug}}</strong>'
                },
                BORROWING: {
                    CREATE: '<strong>{{hospital}}</strong> emprunte {{nbr}} <strong>{{drug}}</strong>',
                    ANSWER: '<strong>{{hospital}}</strong> vous prête {{nbr}} <strong>{{drug}}</strong>',
                    CONFIRM: "<strong>{{hospital}}</strong> a confirmé l'emprunt de {{nbr}} <strong>{{drug}}</strong>",
                    RETURN: "<strong>{{hospital}}</strong> a retourné l'emprunt de {{nbr}} <strong>{{drug}}</strong>",
                    BILL: '<strong>{{hospital}}</strong> a facturé le prêt de {{nbr}} <strong>{{drug}}</strong>',
                    SETTLE: "<strong>{{hospital}}</strong> a liquidé la facture de l'emprunt de {{nbr}} <strong>{{drug}}</strong>"
                },
                AVAILABLE: {
                    CREATE: '<strong>{{hospital}}</strong> a mis à disposition {{nbr}} <strong>{{drug}}</strong>',
                    REQUEST:
                        '<strong>{{hospital}}</strong> souhaite reprendre {{nbr}} <strong>{{drug}}</strong> que vous avez mis à disposition',
                    EXPIRE: 'Votre mise à disposition de {{nbr}} <strong>{{drug}}</strong> a expiré',
                    VALIDATE:
                        '<strong>{{hospital}}</strong> a validé votre demande de reprise pour {{nbr}} <strong>{{drug}}</strong>'
                },
                PREPARATION: {
                    CREATE: '<strong>{{hospital}}</strong> met à votre disposition {{nbr}} <strong>{{drug}}</strong>',
                    REQUEST:
                        '<strong>{{hospital}}</strong> demande la préparation de {{nbr}} <strong>{{drug}}</strong>',
                    VALIDATE: '<strong>{{hospital}}</strong> a validé la préparation de <strong>{{drug}}</strong>',
                    CONFIRM: '<strong>{{hospital}}</strong> a libéré la préparation de <strong>{{drug}}</strong>',
                    'VALIDATE-RECEPTION':
                        '<strong>{{hospital}}</strong> a accusé réception de la préparation de <strong>{{drug}}</strong>',
                    BILL: '<strong>{{hospital}}</strong> a facturé la préparation de {{nbr}} <strong>{{drug}}</strong>',
                    SETTLE: '<strong>{{hospital}}</strong> a liquidé la facture de la préparation de {{nbr}} <strong>{{drug}}</strong>'
                },
                REQUEST: {
                    CREATE: '<strong>{{hospital}}</strong> a besoin de {{nbr}} <strong>{{drug}}</strong>',
                    EXPIRE: 'Votre demande de {{nbr}} <strong>{{drug}}</strong> a expiré'
                },
                HOSPITAL: {
                    'RANK-MEMBER': 'Votre établissement est dorénavant membre premium !'
                },
                SHAREDFILE: {
                    CREATE: 'Le fichier <strong>{{filename}}</strong> a été partagé avec vous dans le dossier <strong>{{parent}}</strong>'
                }
            }
        },
        VAULT_MODAL: {
            TITLE: 'Inactivité détectée',
            CONTENT: 'Votre session sera verrouillée.'
        }
    }
};
