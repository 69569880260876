// @Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Librairies
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { NgScrollbarModule } from 'ngx-scrollbar';

// Material Modules
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

// MaPUI Modules
import { DynamicDataContainerModule } from './components/dynamic-data-container/dynamic-data-container.module';
import { FileDragNDropModule } from './components/file-drag-n-drop/file-drag-n-drop.module';
import { FuseSidebarModule } from './components/fuse-sidebar/fuse-sidebar.module';
import { GenericTableModule } from './components/generic-table/generic-table.module';
import { MessageIconBoxModule } from './components/message-icon-box/message-icon-box.module';
import { SmallListModule } from './components/mpx-small-list/mpx-small-list.module';
import { PageHeaderModule } from './components/page-header/page-header.module';
import { PageMenuSidebarModule } from './components/page-menu-sidebar/page-menu-sidebar.module';
import { DirectivesModule } from './directives/directives';
import { PipesModule } from './pipes/pipes.module';

// Components
import { FlexLayoutModule } from '@angular/flex-layout';
import { HsPublicationWidgetComponent } from '@app/shared/components/widgets/home/publication-widget/publication-widget.component';
import { TranslateModule } from '@ngx-translate/core';
import { SearchCladimedComponent } from '@shared/components/search-cladimed/search-cladimed.component';
import { SearchIndustrialComponent } from '@shared/components/search-industrial/search-industrial.component';
import { ChangeHospitalComponent } from './components/change-hospital/change-hospital.component';
import { ChipComponent } from './components/chip/chip.component';
import { CompactEditMenuComponent } from './components/compact-edit-menu/compact-edit-menu.component';
import { CreateHospitalMemberComponent } from './components/create-hospital-member/create-hospital-member.component';
import { DatesSelectorComponent } from './components/dates/dates-selector/dates-selector.component';
import { MpxDialogContentComponent } from './components/dialog-content/dialog-content.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { ListHospitalsSidebarComponent } from './components/filters-sidebar/list-hospitals-sidebar.component';
import { FiltersAdvancedComponent } from './components/filters/advanced/filters-advanced.component';
import { FiltersAdvancedHeaderComponent } from './components/filters/advanced/header/filters-advanced-header.component';
import { ForbiddenPermissionViewComponent } from './components/forbidden-permission-view/forbidden-permission-view.component';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { GenericMapComponent } from './components/generic-map/generic-map.component';
import { GroupCellComponent } from './components/group-cell/group-cell.component';
import { LoanStatusComponent } from './components/loan-status/loan-status.component';
import { ModalGenericTableComponent } from './components/modals/modal-generic-table/modal-generic-table.component';
import { PageLayoutCardHeaderComponent } from './components/page-layout/page-layout-card-header/page-layout-card-header.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { PasswordHintComponent } from './components/password-hint/password-hint.component';
import { RegionSelectComponent } from './components/region-select/region-select.component';
import { ReleaseNoteWidgetComponent } from './components/release-note-widget/release-note-widget.component';
import { ResponsiveBtnComponent } from './components/responsive-btn/responsive-btn.component';
import { RolesCellComponent } from './components/roles-cell/roles-cell.component';
import {
    SearchBarComponent,
    SearchBarOptionCustomTemplateDirective
} from './components/search-bar/search-bar.component';
import { SearchCountryIsoComponent } from './components/search-country-iso/search-country-iso.component';
import { SearchDciComponent } from './components/search-dci/search-dci.component';
import { SearchEstablishmentFinessComponent } from './components/search-establishment-finess/search-establishment-finess.component';
import { SearchGroupMemberComponent } from './components/search-group-member/search-group-member.component';
import { SearchHospitalExternalsComponent } from './components/search-hospital-externals/search-hospital-externals.component';
import { SearchHospitalMemberComponent } from './components/search-hospital-member/search-hospital-member.component';
import { SearchHospitalComponent } from './components/search-hospital/search-hospital.component';
import { SearchLaboratoryExpComponent } from './components/search-laboratory-exp/search-laboratory-exp.component';
import { MedicSearchCleanDciComponent } from './components/search-medic-clean-dci/search-medic-clean-dci.component';
import { SearchMedicComponent } from './components/search-medic/search-medic.component';
import { SearchMemberComponent } from './components/search-member/search-member.component';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { SpreadsheetImportModalComponent } from './components/spreadsheet-import-modal/spreadsheet-import-modal.component';
import { SpreadsheetImportReportListComponent } from './components/spreadsheet-import-modal/spreadsheet-import-report-list/spreadsheet-import-report-list.component';
import { StatesMenuComponent } from './components/states-menu/states-menu.component';
import { TagComponent } from './components/tag/tag.component';
import { HomeWidgetComponent } from './components/widgets/home/home-widget/home-widget.component';
import { LoanOrBorrowWidgetComponent } from './components/widgets/home/loan-or-borrow-widget/loan-or-borrow-widget.component';
import { HsPublicationDrugWidgetComponent } from './components/widgets/home/publication-widget/drug/publication-drug-widget.component';
import { HsPublicationMedicalDeviceWidgetComponent } from './components/widgets/home/publication-widget/medical-device/publication-medical-device-widget.component';

@NgModule({
    imports: [
        // @Angular
        CommonModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule,
        ReactiveFormsModule,

        // Third Party Librairies
        InfiniteScrollModule,
        LeafletModule,
        NgxChartsModule,
        QuillModule.forRoot(),
        TranslateModule,
        NgScrollbarModule,

        // Material Modules
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatRadioModule,

        // MaPUI Modules
        DirectivesModule,
        DynamicDataContainerModule,
        FileDragNDropModule,
        GenericTableModule,
        MessageIconBoxModule,
        PageHeaderModule,
        PageMenuSidebarModule,
        PipesModule,
        FuseSidebarModule,
        SidebarModule,
        SmallListModule
    ],
    declarations: [
        // Components
        ChangeHospitalComponent,
        ChipComponent,
        CompactEditMenuComponent,
        CreateHospitalMemberComponent,
        DatesSelectorComponent,
        FileUploaderComponent,
        FileViewerComponent,
        FiltersAdvancedComponent,
        FiltersAdvancedHeaderComponent,
        ForbiddenPermissionViewComponent,
        GenericDialogComponent,
        GenericMapComponent,
        GroupCellComponent,
        HomeWidgetComponent,
        HsPublicationDrugWidgetComponent,
        HsPublicationMedicalDeviceWidgetComponent,
        HsPublicationWidgetComponent,
        ListHospitalsSidebarComponent,
        LoanOrBorrowWidgetComponent,
        LoanStatusComponent,
        MedicSearchCleanDciComponent,
        ModalGenericTableComponent,
        MpxDialogContentComponent,
        PageLayoutCardHeaderComponent,
        PageLayoutComponent,
        PasswordHintComponent,
        RegionSelectComponent,
        ReleaseNoteWidgetComponent,
        ResponsiveBtnComponent,
        RolesCellComponent,
        SearchBarComponent,
        SearchCladimedComponent,
        SearchCountryIsoComponent,
        SearchDciComponent,
        SearchEstablishmentFinessComponent,
        SearchGroupMemberComponent,
        SearchHospitalComponent,
        SearchHospitalExternalsComponent,
        SearchHospitalMemberComponent,
        SearchIndustrialComponent,
        SearchLaboratoryExpComponent,
        SearchMedicComponent,
        SearchMemberComponent,
        SpreadsheetImportModalComponent,
        SpreadsheetImportReportListComponent,
        StatesMenuComponent,
        TagComponent,

        SearchBarOptionCustomTemplateDirective
    ],
    exports: [
        // @Angular
        FlexLayoutModule,

        // Third Party Librairies
        TranslateModule,
        NgScrollbarModule,

        // MaPUI Modules
        DirectivesModule,
        DynamicDataContainerModule,
        FileDragNDropModule,
        GenericTableModule,
        MessageIconBoxModule,
        PageHeaderModule,
        PageMenuSidebarModule,
        PipesModule,
        FuseSidebarModule,
        SidebarModule,
        SmallListModule,

        // Components
        ChangeHospitalComponent,
        ChipComponent,
        CompactEditMenuComponent,
        CreateHospitalMemberComponent,
        DatesSelectorComponent,
        FileUploaderComponent,
        FileViewerComponent,
        FiltersAdvancedComponent,
        FiltersAdvancedHeaderComponent,
        ForbiddenPermissionViewComponent,
        GenericDialogComponent,
        GenericMapComponent,
        GroupCellComponent,
        HsPublicationDrugWidgetComponent,
        HsPublicationMedicalDeviceWidgetComponent,
        HsPublicationWidgetComponent,
        ListHospitalsSidebarComponent,
        LoanStatusComponent,
        MedicSearchCleanDciComponent,
        ModalGenericTableComponent,
        MpxDialogContentComponent,
        PageLayoutCardHeaderComponent,
        PageLayoutComponent,
        PasswordHintComponent,
        RegionSelectComponent,
        ReleaseNoteWidgetComponent,
        ResponsiveBtnComponent,
        RolesCellComponent,
        SearchBarComponent,
        SearchCladimedComponent,
        SearchCountryIsoComponent,
        SearchDciComponent,
        SearchEstablishmentFinessComponent,
        SearchGroupMemberComponent,
        SearchHospitalComponent,
        SearchHospitalExternalsComponent,
        SearchHospitalMemberComponent,
        SearchIndustrialComponent,
        SearchLaboratoryExpComponent,
        SearchMedicComponent,
        SearchMemberComponent,
        SpreadsheetImportModalComponent,
        StatesMenuComponent,
        TagComponent,

        SearchBarOptionCustomTemplateDirective
    ]
})
export class SharedModule {}
