import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { DoBootstrap, ErrorHandler, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { IconRegisterModule } from './icon-register.module';
import { LayoutModule } from './layout/layout.module';
import { MpMatPaginatorIntl } from './paginator-intl';

import 'moment/locale/fr';

export const FR_DATE_FORMAT: MatDateFormats = {
    parse: {
        dateInput: 'DD/MM/YYYY'
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

registerLocaleData(localeFr);
registerLocaleData(localeEn);

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        HttpClientModule,
        IconRegisterModule,
        LayoutModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot()
    ],
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
        { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
        { provide: MAT_DATE_FORMATS, useValue: FR_DATE_FORMAT },
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { pageSizeOptions: [10, 25, 50, 100] } },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { hideDelay: 200, showDelay: 400 } },
        { provide: MatPaginatorIntl, useClass: MpMatPaginatorIntl },
        { provide: NG_SCROLLBAR_OPTIONS, useValue: { visibility: 'hover' } }
    ]
})
export class AppModule implements DoBootstrap {
    constructor(private _translateService: TranslateService) {}

    ngDoBootstrap(): void {
        this._translateService.addLangs(['en', 'fr']);
    }
}
