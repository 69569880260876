<ng-container *ngIf="!item.hidden">
    <!-- normal collapsable -->
    <a *ngIf="!item.url && !item.function" class="nav-link" [ngClass]="item.classes" (click)="toggleOpen($event)">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.url -->
    <a
        *ngIf="item.url && !item.externalUrl && !item.function"
        class="nav-link"
        [ngClass]="item.classes"
        [routerLink]="[item.url]"
        [target]="item.openInNewTab ? '_blank' : '_self'"
        (click)="toggleOpen($event)"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a
        *ngIf="item.url && item.externalUrl && !item.function"
        class="nav-link"
        [ngClass]="item.classes"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
        (click)="toggleOpen($event)"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span
        *ngIf="!item.url && item.function"
        class="nav-link"
        [ngClass]="item.classes"
        (click)="toggleOpen($event); item.function()"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a
        *ngIf="item.url && !item.externalUrl && item.function"
        class="nav-link"
        [ngClass]="item.classes"
        [routerLink]="[item.url]"
        (click)="toggleOpen($event); item.function()"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a
        *ngIf="item.url && item.externalUrl && item.function"
        class="nav-link"
        [ngClass]="item.classes"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
        (click)="toggleOpen($event); item.function()"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon *ngIf="item.icon" class="nav-link-icon">{{ item.icon }}</mat-icon>
        <mat-icon *ngIf="item.svg" class="nav-link-icon" [svgIcon]="item.svg"></mat-icon>
        <span class="nav-link-title" [translate]="item.translate">{{ item.title }}</span>
        <span
            *ngIf="item.badge"
            class="nav-link-badge"
            [translate]="item.badge.translate"
            [ngStyle]="{ 'background-color': item.badge.bg, color: item.badge.fg }"
        >
            {{ item.badge.title }}
        </span>
        <mat-icon class="collapsable-arrow">keyboard_arrow_right</mat-icon>
    </ng-template>

    <div class="children" [@slideInOut]="isOpen">
        <ng-container *ngFor="let item of item.children">
            <fuse-nav-vertical-item *ngIf="item.type === 'item'" [item]="item"></fuse-nav-vertical-item>
            <fuse-nav-vertical-collapsable
                *ngIf="item.type === 'collapsable'"
                [item]="item"
            ></fuse-nav-vertical-collapsable>
            <fuse-nav-vertical-group *ngIf="item.type === 'group'" [item]="item"></fuse-nav-vertical-group>
        </ng-container>
    </div>
</ng-container>
