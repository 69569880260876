import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from '../../../i18n/en';
import { locale as french } from '../../../i18n/fr';
import { IdentityService } from '../identity/identity.service';

export interface Locale {
    lang: string;
    data: unknown;
}

@Injectable({
    providedIn: 'root'
})
export class FuseTranslationLoaderService {
    /**
     * Constructor
     *
     * @param {TranslateService} _translateService
     * @param {IdentityService} _identityService
     */
    constructor(
        private _translateService: TranslateService,
        private _identityService: IdentityService
    ) {
        if (_identityService.user?.config?.language) {
            this._translateService.setDefaultLang(this._identityService.user.config.language);
        } else {
            this._translateService.setDefaultLang('fr');
        }
        this.loadTranslations(french, english);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Load translations
     *
     * @param {Locale} args
     */
    loadTranslations(...args: Locale[]): void {
        const locales = [...args];

        locales.forEach((locale) => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this._translateService.setTranslation(locale.lang, locale.data, true);
        });
    }
}
