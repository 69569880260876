import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NotificationService } from '@core/services/notification/notification.service';
import { FuseTranslationLoaderService } from '@core/services/translation-loader/translation-loader.service';
import { INotification } from '@mapuilabs/mpl-interfaces';
import { FuseSidebarService } from '@shared/components/fuse-sidebar/fuse-sidebar.service';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';

@Component({
    selector: 'mpx-notification-panel',
    templateUrl: './notification-panel.component.html',
    styleUrls: ['./notification-panel.component.scss']
})
export class NotificationPanelComponent implements OnInit, AfterViewInit {
    public allLoaded = false;

    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public notificationService: NotificationService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }

    ngOnInit(): void {
        this.notificationService.getCount();
        this.notificationService.listenNotifications();
    }

    ngAfterViewInit(): void {
        this._fuseSidebarService.getSidebar('quickPanel').openedChanged.subscribe((opened) => {
            if (opened) {
                this.onOpened();
            }
        });
    }

    public async onOpened(): Promise<void> {
        await this.notificationService.load();
        this.notificationService.markAllAs('seen');
        await this.notificationService.getCount();
    }

    public markAllAsRead(): void {
        this.notificationService.markAllAs('read');
    }

    public onClickNotification(notification: INotification): void {
        this.notificationService.open(notification);
    }
}
