import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    constructor(
        private _translateService: TranslateService // ngx translate service
    ) {}

    /**
     * Gets the translation of a given value
     * @param from: Object containing the needed translation
     * @param value
     * @param interpolateParams
     * @returns {string}
     */
    public getTranslation = (from: any, value: number | string, interpolateParams?: any): string => {
        if (!from || value == null) {
            return '';
        }
        // isNaN(undefined) = true but isNaN(null) is false
        // !value = false if value = 0

        for (const key in from) {
            if (from[key]?.value === value || from[key]?.stringValue === value) {
                return this.instant(from[key].translate, interpolateParams);
            }
        }
        return '';
    };

    /**
     * Calls the ngx TranslateService instant method. Only one service is
     * instantiated in components' controllers.
     * @param key
     * @param interpolateParams
     */
    public instant(keys: string | string[], interpolateParams?: any): string {
        if (Array.isArray(keys)) {
            return keys
                .map((key, index) => {
                    const translation = String(this._translateService.instant(key, interpolateParams));
                    return index === 0 ? translation : translation.toLocaleLowerCase();
                })
                .join(' ');
        } else {
            return this._translateService.instant(keys, interpolateParams);
        }
    }

    public setLanguage(lang: string): Observable<any> {
        return this._translateService.use(lang);
    }

    /**
     * Call the ngx TranslateService getDefaultLang
     * @returns {string} the current language
     */
    public getCurrentLang(): string {
        return this._translateService.getDefaultLang();
    }
}
