<div class="display-flex flex-row h-64 items-center place-content-around">
    <h3>{{ 'NOTIFICATIONS.TITLE' | translate | uppercase }}</h3>

    <button
        *ngIf="notificationService.notifications.length"
        mat-icon-button
        [matTooltip]="'NOTIFICATIONS.MARK_ALL_AS_READ' | translate"
        (click)="markAllAsRead()"
    >
        <mat-icon>mark_email_read</mat-icon>
    </button>

    <button mat-icon-button routerLink="/setting/user-account" [matTooltip]="'NOTIFICATIONS.SETTINGS' | translate">
        <mat-icon>settings</mat-icon>
    </button>
</div>

<div *ngIf="!notificationService.notifications.length; else hasNotifications">
    <h4 class="pl-16">{{ 'NOTIFICATIONS.NO_NOTIF' | translate | uppercase }}</h4>
</div>

<ng-template #hasNotifications>
    <mat-list class="pt-0">
        <mat-list-item
            *ngFor="let notif of notificationService.notifications"
            class="h-100 border-bottom"
            [ngClass]="{ 'primary-100': !notif.read, 'primary-200': !notif.seen }"
            (click)="onClickNotification(notif)"
        >
            <div class="display-flex flex-row items-center">
                <div class="mr-16">
                    <mat-icon mat-list-icon>{{ notif.icon }}</mat-icon>
                </div>

                <div class="display-flex flex-col">
                    <span mat-line class="wrap mb-8" [innerHTML]="notif.label | translate: notif.data"></span>

                    <span mat-line class="wrap">
                        {{ 'NOTIFICATIONS.RELATIVE_TIME' | translate: { diff: notif.relativeTime } }}
                    </span>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
</ng-template>
