import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { PageMenuSidebarItemComponent } from './page-menu-sidebar-item/page-menu-sidebar-item.component';
import { PageMenuSidebarComponent } from './page-menu-sidebar.component';

@NgModule({
    imports: [CommonModule, TranslateModule, FlexLayoutModule, MatRippleModule],
    declarations: [PageMenuSidebarComponent, PageMenuSidebarItemComponent],
    exports: [PageMenuSidebarComponent, PageMenuSidebarItemComponent]
})
export class PageMenuSidebarModule {}
