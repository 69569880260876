export const locale = {
    lang: 'fr',
    data: {
        COMMONS: {
            ACCEPT: 'Accepter',
            ADD: 'Ajouter',
            APPLY: 'Appliquer',
            ARCHIVE: 'Archiver',
            ARCHIVED: 'Archivé',
            ASK_CONFIRM: 'Confirmer ?',
            CANCEL: 'Annuler',
            CONFIRM: 'Confirmer',
            COPY: 'Copier',
            CLOSE: 'Fermer',
            CLOSED: 'Fermé',
            CREATE: 'Créer',
            CREATED: 'Créé',
            DEFAULT: 'Par défaut',
            DELETE: 'Supprimer',
            DELETED: 'Supprimé',
            DENY: 'Refuser',
            DETAIL: 'Détail',
            EDIT: 'Modifier',
            EDITED: 'Modifié',
            FILTER: 'Filtrer',
            NEW: 'Nouveau',
            NO: 'Non',
            NO_RESULT_FOUND: 'Pas de résultat trouvé',
            OK: 'Ok',
            OPTIONAL: 'Optionnel',
            OPEN: `Ouvrir`,
            OPEN_ADJ: 'Ouvert',
            PREV: 'Précédent',
            RESET: 'Réinitialiser',
            REFUSE: 'Refuser',
            SAVE: 'Sauvegarder',
            SAVED: 'Sauvegardé',
            SELECT: 'Sélectionner',
            SEARCH: 'Rechercher',
            UNKNOWN: 'Inconnu',
            UNKNOWN_F: 'Inconnue',
            UNSELECT: 'Désélectionner',
            VALIDATE: 'Valider',
            VALIDATED: 'Validé',
            WELCOME: 'Bonjour',
            YES: 'Oui',
            WORDS: {
                ADMIN: 'Admin',
                ATC: 'ATC',
                BATCH_NUMER: 'N° lot',
                CIP: 'CIP',
                COMMENT: 'Commentaire',
                DATE_CREATION: 'Date de création',
                DATE_END: 'Date de fin',
                DATE_START: 'Date de début',
                DCI: 'DCI',
                DISPENSARY: 'Officine',
                DRUG: 'Médicament',
                DRUGS: 'Médicaments',
                EDIT_MENU: "Menu d'édition",
                EMAIL: 'Email',
                END: 'Fin',
                EXPIRATION_DATE: 'Date de péremption',
                FINESS: 'Finess',
                GROUP: 'Groupe',
                HOSPISTOCK: 'Hospistock',
                HOSPITAL: 'Hôpital',
                INTERNAL_REFERENCE: 'Référence interne',
                LABORATORY: 'Laboratoire',
                MEDICAL_DEVICE: 'Dispositif médical',
                MEDICAL_DEVICES: 'Dispositifs médicaux',
                NAME: 'Nom',
                NEW: 'Nouveau{count, plural, =0{} one{} other{x}}',
                NEXT: 'Suivant',
                NONE: 'Aucun',
                OVER: 'Sur',
                PATIENT: 'Patient',
                PERIOD: 'Période',
                PHARMACODE: 'Pharmacode',
                PHARMACY: 'PUI',
                PREPARATION: 'Préparation',
                PUBLICATION: 'Publication',
                PUBLICATIONS: 'Publications',
                QUANTITY: 'Quantité',
                REASON: 'Raison',
                REF: 'Ref',
                REFERENCE: 'Référence fournisseur',
                ROW: 'Ligne',
                SEARCH: 'Recherche',
                SELECTION: 'Sélection',
                SOURCE: 'Source',
                START: 'Début',
                STATISTICS: 'Statistiques',
                STOCK: 'Stock',
                SUBSCRIBED: 'Adhérent',
                TEMPLATE: 'Modèle',
                TERMINATED_F: 'Terminée',
                TERMINATED: 'Terminé',
                TYPE: 'Type',
                UCD: 'UCD',
                UPDATED: 'Modifié le',
                VIRTUAL_DRUG: 'Méd. virtuel'
            },
            VERBS: {
                ADD: 'Ajouter',
                CONFIRM: 'Confirmer',
                DOWNLOAD: 'Télécharger',
                EXPORT: 'Exporter',
                IMPORT: 'Importer',
                LOAD: 'Charger',
                SEARCH: 'Rechercher',
                SEE: 'Voir',
                UPDATE_PAST_F: 'Mise à jour',
                UPDATE_PAST: 'Mis à jour'
            },
            MESSAGES: {
                LOADING_DATA: 'Chargement des données',
                NO_DATA: 'Aucune donnée',
                SEE_ALL: 'Voir tous',
                SEE_LESS: 'Voir moins',
                SEE_MORE: 'Voir plus'
            },
            COLORS: {
                ACCENT: 'Accentuation',
                BLUE: 'Bleu',
                FUSE_BLACK: 'Fuse Black',
                FUSE_NAVY: 'Fuse Navy',
                FUSE_WHITE: 'Fuse White',
                GREEN: 'Vert',
                PRIMARY: 'Primaire (theme)',
                PURPLE: 'Pourpre',
                RED: 'Rouge',
                INDIGO: 'Indigo',
                WARN: 'Avertissement'
            },
            ERRORS: {
                DEFAULT: 'Une erreur est survenue.',
                BAD_REQUEST: 'Requête erronée.',
                FORBIDDEN: 'Requête non autorisée.',
                NOT_FOUND: 'Ressource non trouvée.',
                INTERNAL_SERVER_ERROR: 'Erreur interne du serveur.',
                CONFLICT: 'Action annulée, synchronisation des données...',
                NO_FILE_CONTENT: 'Impossible de charger le contenu.'
            },
            GENDERS: {
                FEMALE: 'Femme',
                MALE: 'Homme'
            },
            EMPTY_TABLE: `Aucun résultat.`,
            MAIL_PRIORITY: {
                HIGH: 'Priorité haute',
                NORMAL: 'Priorité normale',
                LOW: 'Priorité basse'
            },
            PUNCTUATION: {
                COLON: ' :',
                ELLIPSIS: '...',
                SEMICOLON: ' ;'
            }
        },
        PAGINATOR: {
            ITEMS_PER_PAGE: 'items par page',
            NEXT_PAGE: 'Page suivante',
            PREVIOUS_PAGE: 'Page précédente',
            OF_LABEL: 'sur'
        },
        VALIDATORS: {
            BATCH_NUMBER_LENGTH: 'Cette valeur doit comporter au maximum 20 caractères',
            CLADIMED: 'Sélectionnez un cladimed ou tapez un code cladimed à 6 caractères',
            DATE: 'Cette valeur doit être une date valide',
            EMAIL: `L'email n'est pas au bon format.`,
            INTEGER: 'Cette valeur doit être un nombre entier',
            NUMBER: 'Cette valeur doit être un nombre',
            NUMBER_OR_DECIMAL_NUMBER: 'Cette valeur doit être un nombre ou un nombre décimal',
            REQUIRED: `Ce champ est requis.`,
            MANUAL_CLADIMED: 'Cladimed 6',
            UCD7_OR_13: 'Un UCD doit être composé de 7 ou 13 chiffres',
            PHARMACODE: 'Un pharmacode doit être composé de 7 chiffres',
            MIN_LENGTH_NB: 'Doit contenir {{nb}} caractères minimum.',
            NEED_LOWER_CHAR: 'Doit contenir au moins une minuscule.',
            NEED_NUMBER: 'Doit contenir au moins un chiffre.',
            NEED_UPPER_CHAR: 'Doit contenir au moins une majuscule.',
            HEX_COLOR: 'Doit respecter le format de couleur hexadécimal (e.g #FFFFFF).',
            VALUE_NOT_EQUALS: 'Cette valeur doit être différente de {{value}}'
        },
        UNITS: {
            BYTES: {
                B: 'octets',
                KB: 'Ko',
                MB: 'Mo',
                GB: 'Go',
                TB: 'To'
            }
        },
        TIME: {
            SECONDS: 'secondes',
            HOURS: 'heures',
            DAYS: 'jours',
            DAY_ABBR: 'j',
            WEEKS: 'semaines',
            MONTHS: 'mois',
            YEARS: 'ans'
        },
        HOSPIBOARD: {
            SHARE: 'Partager dans Hospiboard',
            DURATION_UNIT: 'Unité de la durée de partage',
            DURATION_VALUE: 'Durée de partage',
            TYPES_DURATION: {
                DAYS: 'jour',
                WEEKS: 'semaine',
                MONTHS: 'mois',
                INFINITY: 'illimitée'
            }
        },
        MAPUI: {
            GENERAL: {
                CONST_TYPE: {
                    ESTABLISHMENT: {
                        HOSPITAL: 'Hôpital',
                        PHARMACY: 'Pharmacie',
                        EHPAD: 'EHPAD',
                        LABORATORY: 'Laboratoire',
                        COMPANY: 'Entreprise',
                        OTHER: 'Autre',
                        ARS: 'ARS',
                        NATIONAL: 'Entité Nationale',
                        PURCHASE_GROUP: "Groupement d'achat"
                    },
                    GROUP: {
                        TYPES: {
                            ALL: 'Tous',
                            REGION: 'Région',
                            GHT: 'GHT',
                            PURCHASE: 'Achat',
                            ASSOCIATION: 'Association',
                            PRIVATE: 'Groupe privé',
                            MUTUAL: 'Groupe mutualiste',
                            OTHER: 'Autre',
                            PURCHASE_NON_SUBSCRIBED: 'Achat - non adhérent'
                        }
                    }
                }
            },

            OCP: 'Service Colipass par OCP'
        },
        CHANGE_PASSWORD: {
            TITLE: 'Changer de mot de passe',
            EXPIRED: {
                TITLE: 'Votre mot de passe a expiré',
                SUBTITLE: 'Merci de le modifier'
            },
            TEMPORARY: {
                TITLE: 'Le mot de passe temporaire doit être modifié',
                SUBTITLE: 'Créez votre propre mot de passe'
            },
            INPUTS: {
                CURRENT: 'Mot de passe actuel',
                NEW: 'Nouveau mot de passe',
                CONFIRM: 'Confirmer le nouveau mot de passe'
            },
            ERRORS: {
                REQUIRED: 'Ce champs est requis.',
                TOO_SHORT: 'Le mot de passe doit contenir 8 caractères minimum.',
                NO_UPPERCASE: 'Le mot de passe doit contenir au moins une majuscule.',
                NO_LOWERCASE: 'Le mot de passe doit contenir au moins une minuscule.',
                NO_DIGIT: 'Le mot de passe doit contenir au moins un chiffre.',
                MISMATCH: 'Les mots de passe ne correspondent pas.',
                MATCH: 'Le nouveau mot de passe ne peut pas être le mot de passe actuel.',
                WRONG_PASSWORD: 'Le mot de passe est incorrect.'
            },
            VALIDATOR_INFO:
                'Le mot de passe doit contenir au moins 8 caractères dont une majuscule, une minuscule, un chiffre et un caractère spécial.',
            SAVE: 'Sauvegarder le mot de passe',
            SUCCESS_EDITED: 'Mot de passe modifié avec succès'
        },
        SETTINGS: {
            MAILS: {
                SUBSCRIPTION: {
                    LOAN_BORROWING: 'Prêts et Emprunts',
                    AVAILABLE: 'Mises à disposition',
                    PREP: 'Préparations',
                    PD: 'Demandes de produit',
                    NEWSLETTER: 'Newsletters',
                    STOCKOUT: "Ruptures d'approvisionnement",
                    REQUESTS: 'Requêtes de médicaments ou dispositifs médicaux'
                }
            },
            NOTIFICATIONS: {
                SUBSCRIPTION: {
                    LOAN: 'Prêts',
                    BORROWING: 'Emprunts',
                    AVAILABLE: 'Mises à disposition',
                    AVAILABLE_EXPIRE: 'Expiration des mises à disposition',
                    PREPARATION: 'Préparations',
                    REQUEST: 'Demandes de produit',
                    REQUEST_EXPIRE: 'Expiration des demandes de produit',
                    NEWS: 'News',
                    HOSPITAL: 'Nouveaux arrivants sur la plateforme',
                    FILE: 'Nouveaux documents partagés',
                    GROUP: 'Groupes et invitations',
                    USER: 'Utilisateurs',
                    STOCKOUT_LABO: 'Mises à jour des ruptures laboratoire'
                }
            }
        },
        ROLES: {
            TYPES: {
                ESTABLISHMENT: 'Établissement',
                USER: 'Utilisateur'
            },
            SUBSCRIPTIONS: {
                FREE: 'Gratuit',
                TRIAL: "À l'essai",
                SUBSCRIBED: 'Payant',
                NONE: 'Aucun'
            },
            PERMISSIONS: {
                TITLE: 'Permissions',
                SEE_MENU_ITEM: 'Afficher dans le menu',
                READ: 'Lire',
                CREATE: 'Créer',
                EDIT: 'Modifier',
                DELETE: 'Supprimer',
                SHARE: 'Partager',
                CREATE_BORROWING: 'Créer un emprunt',
                CREATE_LOAN: 'Créer un prêt',
                VALIDATE: 'Valider'
            }
        },
        PHARMACY: {
            PREPARATION: {
                CATEGORIES: {
                    HOSPITAL: 'Hôpital',
                    MASTERFUL: 'Magistrale'
                },
                CONDITIONS: {
                    AMBIENT_TEMP: 'Température ambiante',
                    FROM2TO8: 'De +2 à +8°C',
                    NO_LIGHT: "A l'abri de la lumière"
                },
                DOSAGE: 'Dosage',
                GALENIC_FORM: 'Forme galénique',
                VOLUME: 'Volume',
                EXCIPIENTS: 'Excipients',
                PREP_ADMINISTRATION: "Voie d'administration",
                UNIT_PRICE: 'Prix unitaire (indicatif)',
                CONSERVATION_COND: 'Conditions de conservation'
            },
            PUBLICATION: {
                TYPES: {
                    STOCKOUT: 'Rupture de stock',
                    STOCKOUT_SHORT: 'Rupt. stock',
                    QUOTA: 'Contingentement',
                    QUOTA_SHORT: 'Conting.',
                    PRESSURE: 'Tension',
                    COM_ENDED: 'Arrêt de commercialisation',
                    SHORT_EXPIRATION: 'Péremption courte',
                    WHOLESALER_ONLY: 'Circuit grossiste uniquement',
                    TRANSFER_LABO: 'Transfert portefeuille produits',
                    BATCH_WITHDRAWAL: 'Retrait de lot',
                    OVERSTOCK: 'Sur-stock'
                },
                HISTORY: {
                    ALTERNATIVE: {
                        CREATE: "Ajout d'une alternative",
                        DELETE: "Suppression d'une alternative",
                        UPDATE: "Modification d'une alternative",
                        TITLE: 'Alternative',

                        // Alternative Drug
                        UPDATE_DRUG: "Modification d'un médicament d'une alternative",

                        // Alternative Medical device
                        UPDATE_MEDICAL_DEVICE: "Modification d'un dispositif médical d'une alternative",

                        // Alternatives information
                        CREATE_INFORMATION: "Ajout d'une information d'une alternative",
                        DELETE_INFORMATION: "Suppression d'une information d'une alternative",
                        UPDATE_INFORMATION: "Modification d'une information d'une alternative",

                        // Alternatives start date
                        CREATE_START_DATE: "Ajout de la date de début d'une alternative",
                        DELETE_START_DATE: "Suppression de la date de début d'une alternative",
                        UPDATE_START_DATE: "Modification de la date de début d'une alternative",

                        // Alternatives end date
                        CREATE_END_DATE: "Ajout d'une date de réapprovisionnement d'une alternative",
                        DELETE_END_DATE: "Suppression d'une date de réapprovisionnement d'une alternative",
                        UPDATE_END_DATE: "Modification d'une date de réapprovisionnement d'une alternative",

                        // Unit packaging
                        UPDATE_UNIT_PACKAGING: "Modification du conditionnement unitaire d'une alternative"
                    },
                    PUBLICATION: {
                        CREATE: 'Ajout de la publication',
                        DELETE: 'Suppression de la publication',
                        UPDATE: 'Modification de la publication',

                        // Depositary
                        CREATE_DEPOSITARY: "Ajout d'un dépositaire",
                        DELETE_DEPOSITARY: "Suppression d'un dépositaire",
                        UPDATE_DEPOSITARY: "Modification d'un dépositaire",

                        // Drug
                        UPDATE_DRUG: "Modification d'un médicament",
                        UPDATE_DRUG_PRESENTATION: 'Modification de présentation',

                        // Information
                        CREATE_INFORMATION: "Ajout d'une information",
                        DELETE_INFORMATION: "Suppression d'une information",
                        UPDATE_INFORMATION: "Modification d'une information",

                        // End date
                        CREATE_END_DATE: "Ajout d'une date de réapprovisionnement",
                        DELETE_END_DATE: "Suppression d'une date de réapprovisionnement",
                        UPDATE_END_DATE: "Modification d'une date de réapprovisionnement",

                        // Medical device
                        UPDATE_MEDICAL_DEVICE: 'Modification du libellé publication',
                        UPDATE_MEDICAL_DEVICE_PRESENTATION: 'Modification de référence',

                        // Regulatory quota
                        CREATE_REGULATORY_QUOTA: 'Ajout du contingentement réglementaire',
                        DELETE_REGULATORY_QUOTA: 'Suppression du contingentement réglementaire',
                        UPDATE_REGULATORY_QUOTA: 'Modification du contingentement réglementaire',

                        // Remainders conservation
                        CREATE_REMAINDERS_CONSERVATION: 'Ajout de la conservation des reliquats',
                        DELETE_REMAINDERS_CONSERVATION: 'Suppression de conservation des reliquats',
                        UPDATE_REMAINDERS_CONSERVATION: 'Modification de la conservation des reliquats',

                        // Start date
                        CREATE_START_DATE: 'Ajout de la date de début de publication',
                        DELETE_START_DATE: 'Suppression de la date de début de publication',
                        UPDATE_START_DATE: 'Modification de la date de début de publication',

                        // Target groups
                        DELETE_TARGET_GROUPS: "Suppression d'un groupement d'achat",
                        UPDATE_TARGET_GROUPS: "Modification d'un groupement d'achat",

                        // Wholesaler
                        CREATE_WHOLESALER: "Ajout d'un grossiste - répartiteur",
                        DELETE_WHOLESALER: "Suppression d'un grossiste - répartiteur",
                        UPDATE_WHOLESALER: "Modification d'un grossiste - répartiteur"
                    },

                    // Additional Information
                    CREATE_ADDITIONAL_INFORMATION: "Ajout d'une information supplémentaire",
                    DELETE_ADDITIONAL_INFORMATION: "Suppression d'une information supplémentaire",
                    UPDATE_ADDITIONAL_INFORMATION: "Modification d'une information supplémentaire",

                    // Comments
                    CREATE_COMMENT: "Ajout d'un commentaire",
                    DELETE_COMMENT: "Suppression d'un commentaire",
                    UPDATE_COMMENT: "Modification d'un commentaire",

                    // Regulatory quota
                    REGULATORY_QUOTA: {
                        YES: 'Oui',
                        NO: 'Non',
                        UNKNOWN: 'Inconnu'
                    },

                    // Remainders conservation
                    REMAINDERS_CONSERVATION: {
                        YES: 'Oui',
                        NO: 'Non',
                        NOT_APPLICABLE: 'Non applicable'
                    },

                    // Tag
                    CREATE_TAG: "Ajout d'un tag",
                    DELETE_TAG: "Suppression d'un tag",

                    // Type
                    DELETE_PUBLICATION_TYPE: 'Suppression du type de publication',
                    UPDATE_PUBLICATION_TYPE: 'Modification du type de publication',
                    NEW_VALUE: 'Nouvelle valeur ',
                    NO_DETAILS: 'Pas de détails',
                    OLD_VALUE: 'Ancienne valeur ',
                    VALUE: 'Valeur',

                    // Unit packaging
                    UNIT_PACKAGING: {
                        YES: 'Oui',
                        NO: 'Non',
                        UNKNOWN: 'Inconnu'
                    }
                }
            },
            TAGS: {
                ARCHIVED: 'Archivé',
                PUI: 'PUI',
                GA: 'GA',
                FINISHED: 'Terminé',
                BOOKLET: 'Livret',
                MARKET: 'Marché',
                ALTERNATIVE: 'Alternative',
                PROCESSED: 'Traité'
            },
            FILTERS: {
                ADVANCED: {
                    FILTER: 'Filtrer',
                    HIDE: 'Masquer'
                }
            }
        },
        SNACKBAR: {
            OK: 'Validé',
            SERVER: "Une erreur s'est produite",
            DATA: 'Donnée(s) invalide(s)',
            NO_RESULT: 'Aucun résultat',
            ACCESS: 'Vous ne pouvez pas accéder à cette page',
            EMAIL_IN_USE: 'Cet email ne peut pas être utilisé pour créer un compte',
            USER_IN_USE: 'Utilisateur déjà existant',
            PWD_SHORT: 'Le mot de passe doit contenir au minimum 8 caratères',
            CGU_NOT_ACCEPTED: "Vous devez accepter les conditions générale d'utilisation",
            NOT_AVAILABLE_ANYMORE: "Cet élément n'est plus disponible",
            CPS_CARD_UNDETECTED: 'Carte CPS non détectée',
            LOGIN: {
                CAPTCHA_FAIL:
                    "Par sécurité, confirmez la case 'je ne suis pas un robot', si elle n'est pas visible, contacter nous via contact@mapui.fr",
                LOGIN_FAIL: 'Login et/ou mot de passe incorrect',
                EMAIL_NOT_VERIFIED: "Votre compte n'a pas encore été validé par MaPUI",
                TWOFA_FAIL: 'Le Jeton est invalide'
            },
            NO_VALIDATION: "Votre compte n'a pas encore été validé"
        },
        SERVICES: {
            STATUS: {
                TYPES: {
                    LOAN: `Prêt`,
                    AVAILABLE: `Mise à disposition`,
                    BORROW: `Emprunt`
                },
                VOUCHERS: {
                    RETURN: `Bon de retour`,
                    LOAN: `Bon de prêt`,
                    BORROW: `Bon d'emprunt`
                },
                FORCED: {
                    FORCED: `le {{date}} par {{name}}`,
                    CONFIRM: `Confirmation forcée `,
                    RETURN: `Retour forcé le {{date}} par {{name}}`,
                    RELEASE: `Libération forcée `
                },
                WAITING: {
                    AVAILABLE: `En attente de reprise`,
                    ACCEPT: `En attente d'acceptation`,
                    CONFIRM: `En attente de confirmation`,
                    LIBERATE: `En attente de libération`,
                    DENIED: 'Refusé',
                    BILL: `En attente d'avis de paiement`,
                    RETURN: `En attente de retour`,
                    RECEIVE: `En attente de réception`,
                    SETTLE: `En attente de liquidation`,
                    QUARANTINE: `En quarantaine de stérilité`,
                    MANUFACTURING: `En fabrication`
                },
                OK: `Ok`,
                TO: {
                    ACCEPT: `À accepter`,
                    RELEASE: `À libérer`,
                    CONFIRM: `À confirmer`,
                    LIBERATE: `À libérer`,
                    RETURN: `À retourner`,
                    BILL: `À facturer`,
                    SETTLE: `À liquider`,
                    RECEIVE: `À receptionner`
                },
                DID: {
                    ACCEPTED: `Accepté le {{date}}`,
                    RELEASED: `Libéré le {{date}} par {{name}}`,
                    CONFIRMED: `Confirmé le {{date}} par {{name}}`,
                    YOU_RELEASED: `Vous avez libéré le {{date}}`,
                    YOU_CONFIRMED: `Vous avez confirmé le {{date}}`,
                    RETURNED: `Retourné le {{date}}`,
                    PAYED_OFF: `Facture liquidée le {{date}}`,
                    BILLED: `Facturé le {{date}}`
                },
                DENIED: 'Refusé',
                AUTO: `automatiquement`
            }
        }
    }
};
