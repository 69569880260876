<mat-toolbar class="h-64">
    <button class="md:hidden" mat-icon-button (click)="toggleOpen()">
        <mat-icon>menu</mat-icon>
    </button>

    <!-- HOME BUTTON -->
    <button
        *ngIf="canAccessHome"
        class="max-sm:hidden lg:hidden"
        mat-icon-button
        routerLink="/home"
        [matTooltip]="'TOOLBAR.NAV.HOME' | translate"
    >
        <mat-icon>home</mat-icon>
    </button>
    <button *ngIf="canAccessHome" class="max-lg:hidden" mat-button routerLink="/home">
        <mat-icon>home</mat-icon>
        <span class="ml-4">{{ 'TOOLBAR.NAV.HOME' | translate | uppercase }}</span>
    </button>

    <span class="grow"></span>

    <!-- ESTABLISHMENT BUTTON -->
    <button
        *ngIf="canAccessEstablishment"
        class="h-100-p max-sm:hidden"
        mat-button
        routerLink="/setting/hospital-account/information"
    >
        <mat-icon>local_hospital</mat-icon>
        <div class="display-flex establishment-button flex-col max-sm:hidden ml-4">
            <span>{{ user.hospital.name }}</span>
            <span *ngIf="ght" class="group">{{ ght }}</span>
        </div>
    </button>

    <!-- USER MENU -->
    <button class="h-100-p" mat-button [matMenuTriggerFor]="userMenu">
        <div class="display-flex items-center">
            <img class="avatar" src="assets/images/avatars/profile.jpg" />
            <span class="max-sm:hidden">{{ user.fullName || user.email }}</span>
            <mat-icon class="max-sm:hidden s-16">keyboard_arrow_down</mat-icon>
        </div>
    </button>

    <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <!-- LIGHT / DARK MODE -->
        <ng-container *ngIf="user.isAdmin">
            <ng-container *ngIf="scheme === 'dark'; else useDarkMode">
                <button mat-menu-item (click)="setScheme('light')">
                    <mat-icon>wb_sunny</mat-icon>
                    <span>{{ 'Utiliser mode clair' | translate }}</span>
                </button>
            </ng-container>
            <ng-template #useDarkMode>
                <button mat-menu-item (click)="setScheme('dark')">
                    <mat-icon>brightness_2</mat-icon>
                    <span>{{ 'Utiliser mode sombre' | translate }}</span>
                </button>
            </ng-template>
        </ng-container>

        <!-- USER PROFILE -->
        <button *ngIf="canAccessProfile" mat-menu-item routerLink="/setting/user-account">
            <mat-icon>account_circle</mat-icon>
            <span>{{ 'TOOLBAR.PROFILE.MY_PROFILE' | translate }}</span>
        </button>

        <!-- LOGOUT -->
        <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ 'TOOLBAR.PROFILE.LOGOUT' | translate }}</span>
        </button>
    </mat-menu>

    <!-- NOTIFICATIONS -->
    <button mat-icon-button (click)="openNotifPanel()">
        <mat-icon
            aria-hidden="false"
            matBadgeColor="warn"
            [matBadge]="this.notificationService?.formattedCount"
            [matBadgeHidden]="!this.notificationService?.formattedCount?.length"
        >
            notifications
        </mat-icon>
    </button>
</mat-toolbar>
