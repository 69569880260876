import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { EHTTPStatus, EMpStatus } from '@mapuilabs/mpl-interfaces';

import { TranslationService } from '../translation/translation.service';
import { CMpStatus } from '../../../shared/constTypes/errors/mp-status.const';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    private _httpErrors = {
        400: 'COMMONS.ERRORS.BAD_REQUEST',
        403: 'COMMONS.ERRORS.FORBIDDEN',
        404: 'COMMONS.ERRORS.NOT_FOUND',
        409: 'COMMONS.ERRORS.CONFLICT',
        500: 'COMMONS.ERRORS.INTERNAL_SERVER_ERROR'
    };

    private _defaultConfig: SnackBarConfig = {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
    };

    constructor(
        private _snackBar: MatSnackBar,
        private _translationService: TranslationService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Snack bars from message
    // -----------------------------------------------------------------------------------------------------

    public success(message: string, action?: string): void {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'green-100' });
        this._snackBar.open(message, action, config);
    }

    public default(message: string, action?: string): void {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'grey-300' });
        this._snackBar.open(message, action, config);
    }

    public warning(message: string, action?: string): void {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'amber-100' });
        this._snackBar.open(message, action, config);
    }

    public error(message: string, action?: string): void {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'red-100' });
        this._snackBar.open(message, action, config);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Snack bars from HttpError
    // -----------------------------------------------------------------------------------------------------

    public successHttpStatus(status: EHTTPStatus, action?: string): void {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'green-100' });
        const message = this._translationService.instant(status ? this._httpErrors[status] : 'COMMONS.ERRORS.DEFAULT');
        this._snackBar.open(message, action, config);
    }

    public defaultHttpStatus(status: EHTTPStatus, action?: string): void {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'grey-300' });
        const message = this._translationService.instant(status ? this._httpErrors[status] : 'COMMONS.ERRORS.DEFAULT');
        this._snackBar.open(message, action, config);
    }

    public warningHttpStatus(status: EHTTPStatus, action?: string): void {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'amber-100' });
        const message = this._translationService.instant(status ? this._httpErrors[status] : 'COMMONS.ERRORS.DEFAULT');
        this._snackBar.open(message, action, config);
    }

    public errorHttpStatus(status: EHTTPStatus, action?: string): void {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'red-100' });
        const message = this._translationService.instant(status ? this._httpErrors[status] : 'COMMONS.ERRORS.DEFAULT');
        this._snackBar.open(message, action, config);
    }

    // Old

    /**
     * Opens a manually configured snackbar.
     * @param message
     * @param action
     * @param config
     */
    public open = (message: string, action?: string, config?: SnackBarConfig): void => {
        if (!message) {
            return;
        }

        const newConfig: SnackBarConfig = this._applyStyle(config);

        this._snackBar.open(message, action, newConfig);
    };

    /**
     * Opens an error-styled snackbar with a given message.
     * @param message
     * @param action
     * @param config
     */
    public openCustomError = (message: string, action?: string, config?: SnackBarConfig): void => {
        if (!message) {
            return;
        }

        const snackBarConfig: SnackBarConfig = this._applyErrorDefaultStyle(this._applyStyle(config));

        this._snackBar.open(message, action, snackBarConfig);
    };

    /**
     * Opens an error-styled snackbar with a given message.
     * @param action
     * @param config
     */
    public openDefaultError = (action?: string, config?: SnackBarConfig): void => {
        const message: string = this._translationService.instant('COMMONS.ERRORS.DEFAULT');
        const snackBarConfig: SnackBarConfig = this._applyErrorDefaultStyle(this._applyStyle(config));

        this._snackBar.open(message, action, snackBarConfig);
    };

    /**
     * Opens an error-styled snackbar with a message according to the HTTP error.
     * @param error
     * @param action
     * @param config
     */
    public openError = (error: HttpErrorResponse, action?: string, config?: SnackBarConfig): void => {
        if (!error) {
            return;
        }

        let message: string = this._translationService.instant('COMMONS.ERRORS.DEFAULT');
        if (error.status && this._httpErrors[error.status]) {
            message = this._translationService.instant(this._httpErrors[error.status]);
        }

        const snackBarConfig: SnackBarConfig = this._applyErrorDefaultStyle(this._applyStyle(config));

        this._snackBar.open(message, action, snackBarConfig);
    };

    /**
     * Opens an error-styled snackbar with a message according to the HTTP
     * status code.
     * @param status
     * @param action
     * @param config
     */
    public openErrorFromStatus = (status: EHTTPStatus, action?: string, config?: SnackBarConfig): void => {
        if (!status) {
            return;
        }

        let message: string = this._translationService.instant('COMMONS.ERRORS.DEFAULT');
        if (this._httpErrors[status]) {
            message = this._translationService.instant(this._httpErrors[status]);
        }

        const snackBarConfig: SnackBarConfig = this._applyErrorDefaultStyle(this._applyStyle(config));

        this._snackBar.open(message, action, snackBarConfig);
    };

    /**
     * Opens an error-styled snackbar with a message according to the HTTP
     * status code.
     * @param status
     * @param action
     * @param config
     */
    public openErrorMpStatus(status: EMpStatus, action?: string, config?: SnackBarConfig): void {
        if (!status) {
            return;
        }

        let message: string = this._translationService.instant('COMMONS.ERRORS.DEFAULT');

        const mpStatus = CMpStatus.find((mpStatus) => mpStatus.value === status);
        if (mpStatus) {
            message = this._translationService.instant(mpStatus.translate);
        }

        const snackBarConfig: SnackBarConfig = this._applyErrorDefaultStyle(this._applyStyle(config));

        this._snackBar.open(message, action, snackBarConfig);
    }

    /**
     * Applies the given config in the snackbar.
     * @param config
     * @private
     */
    private _applyStyle = (config?: SnackBarConfig): SnackBarConfig => {
        let newConfig: SnackBarConfig = Object.assign({}, this._defaultConfig);
        if (config) {
            newConfig = config;
        }

        return newConfig;
    };

    /**
     * Applies the default style for errors snackbars.
     * @param config
     * @private
     */
    private _applyErrorDefaultStyle = (config: SnackBarConfig): SnackBarConfig => {
        if (!config) {
            return null;
        }

        if (!config.panelClass) {
            config.panelClass = 'warn';
        }
        return config;
    };
}

export class SnackBarConfig extends MatSnackBarConfig {}
