<ng-container *ngIf="!item.hidden">
    <!-- item.url -->
    <a
        *ngIf="item.url && !item.externalUrl && !item.function"
        class="nav-link"
        routerLinkActive="active"
        [ngClass]="item.classes"
        [routerLink]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a
        *ngIf="item.url && item.externalUrl && !item.function"
        class="nav-link"
        [ngClass]="item.classes"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span *ngIf="!item.url && item.function" class="nav-link" [ngClass]="item.classes" (click)="item.function()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a
        *ngIf="item.url && !item.externalUrl && item.function"
        class="nav-link"
        [ngClass]="item.classes"
        [routerLink]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
        (click)="item.function()"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a
        *ngIf="item.url && item.externalUrl && item.function"
        class="nav-link"
        [ngClass]="item.classes"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
        (click)="item.function()"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon *ngIf="item.icon" class="nav-link-icon">{{ item.icon }}</mat-icon>
        <mat-icon *ngIf="item.svg" class="nav-link-icon" [svgIcon]="item.svg"></mat-icon>
        <span class="nav-link-title" [translate]="item.translate">{{ item.title }}</span>
        <span
            *ngIf="item.badge"
            class="nav-link-badge"
            [translate]="item.badge.translate"
            [ngStyle]="{ 'background-color': item.badge.bg, color: item.badge.fg }"
        >
            {{ item.badge.title }}
        </span>
    </ng-template>
</ng-container>
