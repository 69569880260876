export const locale = {
    lang: 'en',
    data: {
        COMMONS: {
            ACCEPT: 'Accept',
            ADD: 'Add',
            APPLY: 'Apply',
            ARCHIVE: 'Archive',
            ARCHIVED: 'Archived',
            ASK_CONFIRM: 'Confirm?',
            CANCEL: 'Cancel',
            CONFIRM: 'Confirm',
            COPY: 'Copy',
            CLOSE: `Close`,
            CLOSED: 'Closed',
            CREATE: 'Create',
            CREATED: 'Created',
            DEFAULT: 'By default',
            DELETE: 'Delete',
            DELETED: 'Deleted',
            DENY: 'Deny',
            DETAIL: 'Detail',
            EDIT: 'Edit',
            EDITED: 'Edited',
            FILTER: 'Filter',
            NEW: 'New',
            NO: 'No',
            NO_RESULT_FOUND: 'No result found',
            OK: 'Ok',
            OPTIONAL: 'Optional',
            OPEN: `Open`,
            OPEN_ADJ: 'Open',
            PREV: 'Prev',
            RESET: 'Reset',
            REFUSE: 'Refuse',
            SAVE: 'Save',
            SAVED: 'Saved',
            SELECT: 'Select',
            SEARCH: 'Search',
            UNKNOWN: 'Unknown',
            UNKNOWN_F: 'Unknown',
            UNSELECT: 'Unselect',
            VALIDATE: 'Validate',
            VALIDATED: 'Validated',
            WELCOME: 'Welcome',
            YES: 'Yes',
            WORDS: {
                ADMIN: 'Admin',
                ATC: 'ATC',
                BATCH_NUMER: 'Batch number',
                CIP: 'CIP',
                COMMENT: 'Comment',
                DATE_CREATION: 'Creation date',
                DATE_END: 'End date',
                DATE_START: 'Start date',
                DCI: 'DCI',
                DISPENSARY: 'Officine',
                DRUG: 'Drug',
                DRUGS: 'Drugs',
                EDIT_MENU: 'Edit menu',
                EMAIL: 'Email',
                END: 'End',
                EXPIRATION_DATE: 'Expiration date',
                FINESS: 'Finess',
                GROUP: 'Group',
                HOSPISTOCK: 'Hospistock',
                HOSPITAL: 'Hospital',
                INTERNAL_REFERENCE: 'Internal reference',
                LABORATORY: 'Laboratory',
                MEDICAL_DEVICE: 'Medical device',
                MEDICAL_DEVICES: 'Medical devices',
                NAME: 'Name',
                NEW: 'New',
                NEXT: 'Next',
                NONE: 'No',
                OVER: 'Over',
                PATIENT: 'Patient',
                PERIOD: 'Period',
                PHARMACODE: 'Pharmacode',
                PHARMACY: 'Pharmacy',
                PREPARATION: 'Preparation',
                PUBLICATION: 'Publication',
                PUBLICATIONS: 'Publications',
                QUANTITY: 'Quantity',
                REASON: 'Reason',
                REF: 'Ref',
                REFERENCE: 'Supplier reference',
                ROW: 'Row',
                SEARCH: 'Search',
                SELECTION: 'Selection',
                SOURCE: 'Source',
                START: 'Start',
                STATISTICS: 'Statistics',
                STOCK: 'Stock',
                SUBSCRIBED: 'Subscribed',
                TEMPLATE: 'Template',
                TERMINATED_F: 'Terminated',
                TERMINATED: 'Terminated',
                TYPE: 'Type',
                UCD: 'UCD',
                UPDATED: 'Updated',
                VIRTUAL_DRUG: 'Virtual drug'
            },
            VERBS: {
                ADD: 'Add',
                CONFIRM: 'Confirm',
                DOWNLOAD: 'Download',
                EXPORT: 'Export',
                IMPORT: 'Import',
                LOAD: 'Load',
                SEARCH: 'Search',
                SEE: 'See',
                UPDATE_PAST_F: 'Updated',
                UPDATE_PAST: 'Updated'
            },
            MESSAGES: {
                LOADING_DATA: 'Loading data',
                NO_DATA: 'No data',
                SEE_ALL: 'See all',
                SEE_LESS: 'See less',
                SEE_MORE: 'See more'
            },
            COLORS: {
                ACCENT: 'Accentuation',
                BLUE: 'Blue',
                FUSE_BLACK: 'Fuse Black',
                FUSE_NAVY: 'Fuse Navy',
                FUSE_WHITE: 'Fuse White',
                GREEN: 'Green',
                PRIMARY: 'Primary (theme)',
                PURPLE: 'Purple',
                RED: 'Red',
                INDIGO: 'Indigo',
                WARN: 'Warning'
            },
            ERRORS: {
                DEFAULT: 'An error has occured.',
                BAD_REQUEST: 'Bad request.',
                FORBIDDEN: 'Forbidden request.',
                NOT_FOUND: 'Resoure not found',
                INTERNAL_SERVER_ERROR: 'Internal server error.',
                CONFLICT: 'Action canceled, data synchronisation...',
                NO_FILE_CONTENT: 'Could not load content.'
            },
            GENDERS: {
                FEMALE: 'Female',
                MALE: 'Male'
            },
            EMPTY_TABLE: `No result.`,
            MAIL_PRIORITY: {
                HIGH: 'High priority',
                NORMAL: 'Normal priority',
                LOW: 'Low priority'
            },
            PUNCTUATION: {
                COLON: ':',
                ELLIPSIS: '...',
                SEMICOLON: ';'
            }
        },
        PAGINATOR: {
            ITEMS_PER_PAGE: 'item per page',
            NEXT_PAGE: 'Next page',
            PREVIOUS_PAGE: 'Previous page',
            OF_LABEL: 'of'
        },
        VALIDATORS: {
            BATCH_NUMBER_LENGTH: 'This value must have a maximum of 20 characters',
            CLADIMED: 'Please select a cladimed or enter a cladimed code composed of 6 characters',
            DATE: 'This value must be a valid date',
            EMAIL: `Email is not in the right format.`,
            INTEGER: 'This value must be an integer',
            REQUIRED: `This field is required.`,
            NUMBER: 'This value must be numeric',
            NUMBER_OR_DECIMAL_NUMBER: 'This value must be a numeric or a decimal number',
            MANUAL_CLADIMED: 'Cladimed 6',
            UCD7_OR_13: 'A UCD must be composed of 7 or 13 digits',
            PHARMACODE: 'A pharmacode must be composed of 7 digits',
            MIN_LENGTH_NB: 'Must contain at least {{nb}} characters.',
            NEED_LOWER_CHAR: 'Must contain at least a lowercase letter.',
            NEED_NUMBER: 'Must contain at least a digit.',
            NEED_UPPER_CHAR: 'Must contain at least an uppercase letter.',
            HEX_COLOR: 'Must follow the hexadecimal color format (e.g #FFFFFF).',
            VALUE_NOT_EQUALS: 'This value must be diiferente from {{value}}'
        },
        UNITS: {
            BYTES: {
                B: 'bytes',
                KB: 'Kb',
                MB: 'Mb',
                GB: 'Gb',
                TB: 'Tb'
            }
        },
        TIME: {
            SECONDS: 'seconds',
            HOURS: 'hours',
            DAYS: 'days',
            DAY_ABBR: 'd',
            WEEKS: 'weeks',
            MONTHS: 'months',
            YEARS: 'years'
        },
        HOSPIBOARD: {
            SHARE: 'Share on Hospiboard',
            DURATION_UNIT: 'Duration sharing unit',
            DURATION_VALUE: 'Sharing duration',
            TYPES_DURATION: {
                DAYS: 'day',
                WEEKS: 'week',
                MONTHS: 'month',
                INFINITY: 'infinity'
            }
        },
        MAPUI: {
            GENERAL: {
                CONST_TYPE: {
                    ESTABLISHMENT: {
                        HOSPITAL: 'Hospital',
                        PHARMACY: 'Pharmacy',
                        EHPAD: 'Long-term care',
                        LABORATORY: 'Laboratory',
                        COMPANY: 'Company',
                        OTHER: 'Other',
                        ARS: 'ARS',
                        NATIONAL: 'National Entity',
                        PURCHASE_GROUP: 'Purchasing Group'
                    },
                    GROUP: {
                        TYPES: {
                            ALL: 'All',
                            REGION: 'Region',
                            GHT: 'GHT',
                            PURCHASE: 'Purchase',
                            ASSOCIATION: 'Association',
                            PRIVATE: 'Private group',
                            MUTUAL: 'Mutual group',
                            OTHER: 'Other',
                            PURCHASE_NON_SUBSCRIBED: 'Purchase - non subscribed'
                        }
                    }
                }
            },

            OCP: 'Service Colipass par OCP'
        },
        CHANGE_PASSWORD: {
            TITLE: 'Change password',
            EXPIRED: {
                TITLE: 'Your password expired',
                SUBTITLE: 'Please change it'
            },
            TEMPORARY: {
                TITLE: 'The temporary password must be changed',
                SUBTITLE: 'Create your own password'
            },
            INPUTS: {
                CURRENT: 'Current password',
                NEW: 'New password',
                CONFIRM: 'Confirm new password'
            },
            ERRORS: {
                REQUIRED: 'This field is required',
                TOO_SHORT: 'Password must contains at least 8 characters',
                NO_UPPERCASE: 'Password must contains at least one uppercase character',
                NO_LOWERCASE: 'Password must contains at least one lowercase character',
                NO_DIGIT: 'Password must contains at least one digit',
                MISMATCH: 'Passwords mismatch',
                MATCH: 'The new password cannot be the current password',
                WRONG_PASSWORD: 'The password is incorrect'
            },
            VALIDATOR_INFO:
                'The password must contains at least 8 characters including at least one uppercase, one lowercase, one digit and one special character',
            SAVE: 'Save password',
            SUCCESS_EDITED: 'Password successfully edited'
        },
        SETTINGS: {
            MAILS: {
                SUBSCRIPTION: {
                    LOAN_BORROWING: 'Loans and Borrowings',
                    AVAILABLE: 'Available',
                    PREP: 'Preparations',
                    PD: 'Product demands',
                    NEWSLETTER: 'Newsletters',
                    STOCKOUT: 'Stockouts',
                    REQUESTS: 'Drugs or medical devices requests'
                }
            },
            NOTIFICATIONS: {
                SUBSCRIPTION: {
                    LOAN: 'Loans',
                    BORROWING: 'Borrowings',
                    AVAILABLE: 'Available',
                    AVAILABLE_EXPIRE: 'Expiring available',
                    PREPARATION: 'Preparations',
                    REQUEST: 'Product demands',
                    REQUEST_EXPIRE: 'Expiring product demands',
                    NEWS: 'News',
                    HOSPITAL: "New hospital's and pharmacies",
                    FILE: 'Shared files',
                    GROUP: 'Groups and invitations',
                    USER: 'Users',
                    STOCKOUT_LABO: 'Update of laboratory stockout'
                }
            }
        },
        ROLES: {
            TYPES: {
                ESTABLISHMENT: 'Establishment',
                USER: 'User'
            },
            SUBSCRIPTIONS: {
                FREE: 'Free',
                TRIAL: 'Trial',
                SUBSCRIBED: 'Subscribed',
                NONE: 'None'
            },
            PERMISSIONS: {
                TITLE: 'Permissions',
                SEE_MENU_ITEM: 'See in menu',
                READ: 'See',
                CREATE: 'Create',
                EDIT: 'Edit',
                DELETE: 'Delete',
                SHARE: 'SHARE',
                CREATE_BORROWING: 'Borrowing create',
                CREATE_LOAN: 'Loan create',
                VALIDATE: 'Validate'
            }
        },
        PHARMACY: {
            PREPARATION: {
                CATEGORIES: {
                    HOSPITAL: 'Hospital',
                    MASTERFUL: 'Masterful'
                },
                CONDITIONS: {
                    AMBIENT_TEMP: 'Ambient temperature',
                    FROM2TO8: 'From +2 to +8°C',
                    NO_LIGHT: 'No light'
                },
                DOSAGE: 'Dosage',
                GALENIC_FORM: 'Galenic form',
                VOLUME: 'Volume',
                EXCIPIENTS: 'Excipients',
                PREP_ADMINISTRATION: 'Route of administration',
                UNIT_PRICE: 'Unit price (indicative)',
                CONSERVATION_COND: 'Conservation conditions'
            },
            PUBLICATION: {
                TYPES: {
                    STOCKOUT: 'Stockout',
                    STOCKOUT_SHORT: 'Stockout',
                    QUOTA: 'Quota',
                    QUOTA_SHORT: 'Quota',
                    PRESSURE: 'Pressure',
                    COM_ENDED: 'End of sale',
                    SHORT_EXPIRATION: 'Short expiration',
                    WHOLESALER_ONLY: 'Wholesaler only',
                    TRANSFER_LABO: 'Transfer',
                    BATCH_WITHDRAWAL: 'Batch withdrawal',
                    OVERSTOCK: 'Overstock'
                },
                HISTORY: {
                    ALTERNATIVE: {
                        CREATE: 'Addition of an alternative',
                        DELETE: 'Deletion of an alternative',
                        UPDATE: 'Modification of an alternative',
                        TITLE: 'Alternative',

                        // Alternative Drug
                        UPDATE_DRUG: 'Modification of an alternative drug',

                        // Alternative Medical device
                        UPDATE_MEDICAL_DEVICE: 'Modification of an alternative medical device',

                        // Alternatives information
                        CREATE_INFORMATION: 'Addition of alternative information',
                        DELETE_INFORMATION: 'Deletion of alternative information',
                        UPDATE_INFORMATION: 'Modification of alternative information',

                        // Alternatives start date
                        CREATE_START_DATE: 'Addition of alternative start date',
                        DELETE_START_DATE: 'Deletion of alternative start date',
                        UPDATE_START_DATE: 'Modification of alternative start date',

                        // Alternatives end date
                        CREATE_END_DATE: 'Addition of alternative replenishment date',
                        DELETE_END_DATE: 'Deletion of alternative replenishment date',
                        UPDATE_END_DATE: 'Modification of alternative replenishment date',

                        // Alternative Unit packaging
                        UPDATE_UNIT_PACKAGING: 'Modification of alternative unit packaging'
                    },
                    PUBLICATION: {
                        CREATE: 'Addition of publication',
                        DELETE: 'Deletion of publication',
                        UPDATE: 'Modification of publication',

                        // Depositary
                        CREATE_DEPOSITARY: 'Addition of a depositary',
                        DELETE_DEPOSITARY: 'Deletion of a depositary',
                        UPDATE_DEPOSITARY: 'Modification of a depositary',

                        // Drug
                        UPDATE_DRUG: 'Modification of a drug',
                        UPDATE_DRUG_PRESENTATION: 'Modification of presentations',

                        // Information
                        CREATE_INFORMATION: 'Addition of information',
                        DELETE_INFORMATION: 'Deletion of information',
                        UPDATE_INFORMATION: 'Modification of information',

                        // End date
                        CREATE_END_DATE: 'Addition of replenishment date',
                        DELETE_END_DATE: 'Deletion of replenishment date',
                        UPDATE_END_DATE: 'Modification of replenishment date',

                        // Medical device
                        UPDATE_MEDICAL_DEVICE: 'Modification of a medical device group name',
                        UPDATE_MEDICAL_DEVICE_PRESENTATION: 'Modification of reference',

                        // Regulatory quota
                        CREATE_REGULATORY_QUOTA: 'Addition of regulatory quota',
                        DELETE_REGULATORY_QUOTA: 'Deletion of regulatory quota',
                        UPDATE_REGULATORY_QUOTA: 'Modification of regulatory quota',

                        // Remainders conservation
                        CREATE_REMAINDERS_CONSERVATION: 'Addition of remainder conservation',
                        DELETE_REMAINDERS_CONSERVATION: 'Deletion of remainder conservation',
                        UPDATE_REMAINDERS_CONSERVATION: 'Modification of remainder conservation',

                        // Start date
                        CREATE_START_DATE: 'Addition of publication start date',
                        DELETE_START_DATE: 'Deletion of publication start date',
                        UPDATE_START_DATE: 'Modification of publication start date',

                        // Target groups
                        DELETE_TARGET_GROUPS: 'Deletion of target groups',
                        UPDATE_TARGET_GROUPS: 'Modification of target groups',

                        // Wholesaler
                        CREATE_WHOLESALER: 'Addition of a wholesaler - distributor',
                        DELETE_WHOLESALER: 'Deletion of a wholesaler - distributor',
                        UPDATE_WHOLESALER: 'Modification of a wholesaler - distributor'
                    },

                    // Additional Information
                    CREATE_ADDITIONAL_INFORMATION: 'Creation of additional information',
                    DELETE_ADDITIONAL_INFORMATION: 'Deletion of additional information',
                    UPDATE_ADDITIONAL_INFORMATION: 'Modification of additional information',

                    // Comments
                    CREATE_COMMENT: 'Addition of a comment',
                    DELETE_COMMENT: 'Deletion of a comment',
                    UPDATE_COMMENT: 'Modification of a comment',

                    // Regulatory quota
                    REGULATORY_QUOTA: {
                        YES: 'Yes',
                        NO: 'No',
                        UNKNOWN: 'Unknown'
                    },

                    // Remainders conservation
                    REMAINDERS_CONSERVATION: {
                        YES: 'Yes',
                        NO: 'No',
                        NOT_APPLICABLE: 'Not applicable'
                    },

                    // Tag
                    CREATE_TAG: "Ajout d'un tag",
                    DELETE_TAG: "Suppression d'un tag",

                    // Type
                    DELETE_PUBLICATION_TYPE: 'Deletion of publication type',
                    UPDATE_PUBLICATION_TYPE: 'Modification of publication type',
                    NEW_VALUE: 'New value',
                    NO_DETAILS: 'No details',
                    OLD_VALUE: 'Old value',
                    VALUE: 'Value',

                    // Unit packaging
                    UNIT_PACKAGING: {
                        YES: 'Yes',
                        NO: 'No',
                        UNKNOWN: 'Unknown'
                    }
                }
            },
            TAGS: {
                ARCHIVED: 'Archived',
                PUI: 'Pharmacy',
                GA: 'Purchasing group',
                FINISHED: 'Finished',
                BOOKLET: 'Booklet',
                MARKET: 'Market',
                ALTERNATIVE: 'Alternative',
                PROCESSED: 'Processed'
            },
            FILTERS: {
                ADVANCED: {
                    FILTER: 'Filter',
                    HIDE: 'Hide'
                }
            }
        },
        SNACKBAR: {
            OK: 'Effective',
            SERVER: 'An error occurred',
            DATA: 'Invalid data',
            NO_RESULT: 'No result',
            ACCESS: "You don't have the access to this page",
            EMAIL_IN_USE: 'Email already in use',
            USER_IN_USE: 'Username already taken',
            PWD_SHORT: 'The password must be at least 8 characters long',
            CGU_NOT_ACCEPTED: 'You must accept the general conditions of use',
            NOT_AVAILABLE_ANYMORE: 'This element is unavailable',
            CPS_CARD_UNDETECTED: 'CPS card undetected',
            LOGIN: {
                CAPTCHA_FAIL:
                    "For safety measures check the 'I am not a robot' case. If it is not visible please contact us at contact@mapui.fr",
                LOGIN_FAIL: 'Authentication error',
                EMAIL_NOT_VERIFIED: 'Your account is not yet validated by MaPUI',
                TWOFA_FAIL: 'The token is invalid'
            },
            NO_VALIDATION: "Your account hasn't been validated"
        },
        SERVICES: {
            STATUS: {
                TYPES: {
                    LOAN: `Loan`,
                    AVAILABLE: `Available`,
                    BORROW: `Borrow`
                },
                VOUCHERS: {
                    RETURN: `Return voucher`,
                    LOAN: `Loan voucher`,
                    BORROW: `Borrowing voucher`
                },
                FORCED: {
                    FORCED: `the {{date}} by {{name}}`,
                    CONFIRM: `Confirmation forced `,
                    RETURN: `Return forced the {{date}} by {{name}}`,
                    RELEASE: `Release forced`
                },
                WAITING: {
                    AVAILABLE: `Waiting for recovery`,
                    ACCEPT: `Waiting for acceptation`,
                    CONFIRM: `Waiting for confirmation`,
                    LIBERATE: `Waiting for release`,
                    DENIED: 'Denied',
                    BILL: `Waiting for billing`,
                    RETURN: `Waiting for return`,
                    RECEIVE: `Waiting for reception`,
                    SETTLE: `Waiting for settlement`,
                    QUARANTINE: `Sterility quarantine`,
                    MANUFACTURING: `Manufacturing`
                },
                OK: `Ok`,
                TO: {
                    ACCEPT: `To accept`,
                    RELEASE: `To release`,
                    CONFIRM: `To confirm`,
                    LIBERATE: `To liberate`,
                    RETURN: `To return`,
                    BILL: `To bill`,
                    SETTLE: `To settle`,
                    RECEIVE: `To receive`
                },
                DID: {
                    ACCEPTED: `Accepté le {{date}}`,
                    RELEASED: `Libéré le {{date}} par {{name}}`,
                    CONFIRMED: `Confirmé le {{date}} par {{name}}`,
                    YOU_RELEASED: `Vous avez libéré le {{date}}`,
                    YOU_CONFIRMED: `Vous avez confirmé le {{date}}`,
                    RETURNED: `Retourné le {{date}}`,
                    PAYED_OFF: `Facture liquidée le {{date}}`,
                    BILLED: `Facturé le {{date}}`
                },
                DENIED: 'Refusé',
                AUTO: `automatically`
            }
        }
    }
};
